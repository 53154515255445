import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.nicescroll.min'
// import '../../assets/scripts/countNumber.js'


// 自定义滚动条插件
if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        $('body').niceScroll({
            mousescrollstep: 40,
            scrollspeed: 80,
            zindex: 101,
            cursorcolor: '#BDBDBD',
            cursorborder: '1px solid #BDBDBD',
            cursorwidth: '5px',
            cursoropacitymax: 0.6,
            smoothscroll: true,
            iframeautoresizeL: true,
        });
    }
}
function resizeNiceScroll () {
    if (window.devicePixelRatio < 2) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            setTimeout(() => {
                $('body').getNiceScroll().resize()
            }, 500);
        }
    }
}

window.onload = function() {
    var endTime = (new Date()).getTime();
    var millisecondsLoading = endTime - startTime;
    if(millisecondsLoading > 3000){
        $('.loading').fadeOut()
        $('.comp-root').css({
            height: 'auto',
            overflowY: 'auto'
        })
        bannerInit()
        resizeNiceScroll ()
    }else{
        setTimeout(function(){
            $('.loading').fadeOut()
            $('.comp-root').css({
                height: 'auto',
                overflowY: 'auto'
            })
            bannerInit()
            resizeNiceScroll ()
        },2000)
    }
};

//首页banner图
var bannerswiper
var screenWidth = window.screen.width;
var indexinterleaveOffset = 0.4; //视差比值
function bannerInit(){
    bannerswiper = new Swiper(".index-banner", {
        direction: "vertical",
        loop: true,
        speed: 1000,
        watchSlidesProgress: true,
        autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.index-banner .swiper-button-next',
            prevEl: '.index-banner .swiper-button-prev',
        },
        pagination: {
            el: '.index_banner .swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<li class="' + className + '"><span>' + '</span></li>';
            },
        },
        on: {
            progress: function (swiper) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    var slideProgress = swiper.slides[ i ].progress;
                    var innerOffset = swiper.width * indexinterleaveOffset;
                    var innerTranslate = slideProgress * innerOffset;
                    swiper.slides[ i ].querySelector(".slide-inner").style.transform =
                        "translate3d(0," + innerTranslate + "px, 0)";
                }
            },
            touchStart: function (swiper) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[ i ].style.transition = "";
                }
            },
            setTransition: function (swiper, speed) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[ i ].style.transition = speed + "ms";
                    swiper.slides[ i ].querySelector(".slide-inner").style.transition =
                        speed + "ms";
                }
            }
        }
    });
}


//关于我们数字滚动效果
// function numScroll () {
//     let num1 = $("#scrollNum1").html()
//     let num2 = $("#scrollNum2").html()
//     let num3 = $("#scrollNum3").html()
//     let num4 = $("#scrollNum4").html()
//     $("#scrollNum1").rollNum({
//         deVal: num1,
//         time: 2000
//     });
//     $("#scrollNum2").rollNum({
//         deVal: num2,
//         time: 5000
//     });
//     $("#scrollNum3").rollNum({
//         deVal: num3,
//         time: 5000
//     });
//     $("#scrollNum4").rollNum({
//         deVal: num4,
//         time: 5000
//     });
// }
!(
  function () {
    $(".header .right").addClass('active')
  }
)()


//页面滚动监听
$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var contentH = $(document).height();


    // 首页菜单背景图显示隐藏
    if (scroH >= 150) {
        if(!$('.header-nav').hasClass('active')){
            $(".header .right").removeClass('active')
            $(".header .nav-bg").addClass('active')
        }
    }else {
        if(!$('.header-nav').hasClass('active')){
            $(".header .right").addClass('active')
            $(".header .nav-bg").removeClass('active')
        }
    }

    //关于我们数字滚动触发
    // var numScollH = $('.num-cont').offset().top;
    // if (scroH > numScollH - viewH / .8) {
    //     numScroll()
    // }
    // 关于我们图片位移
    var aboutImgTop1 = $('.index-about .cont2 .left-img').offset().top;
    var aboutImgBtm1 = $('.index-about .cont2 .left-img').offset().top + $('.index-about .cont2 .left-img').height();
    if (scroH > aboutImgTop1 - viewH && scroH < aboutImgBtm1) {
        let trans = (scroH - aboutImgTop1 + viewH) / 13
        $('.index-about .cont2 .left-img .wy').css('transform', 'translateY(' + -trans + 'px)');
    }
    var aboutImgTop2 = $('.index-about .cont1 .right-img').offset().top;
    if (scroH > aboutImgTop2 - viewH / .5) {
        let trans = (scroH - aboutImgTop2 + viewH ) / 20 + 70
        if(trans > 100){
            trans = 100
        }
        $('.index-about .cont1 .right-img img').css('transform', 'translateY(' + -trans + '%)');
    }

    if ($(window).width() > 993) {
        //pc端首页产品部分效果
        let proTop = $('.index_pro').offset().top
        let marketingTop = $('footer').offset().top

        //产品部分文字浮动
        if (scroH <= proTop) {
            $('.index_pro').removeClass('fixed')
        } else if (scroH > proTop && scroH < marketingTop - viewH) {
            $('.index_pro').addClass('fixed').removeClass('abs')
        } else if (scroH >= marketingTop - viewH) {
            $('.index_pro').addClass('abs')
        }

        
        let $navElArr = $('.index_pro .img-cont .item')
        let $textElArr = $('.index_pro .text-cont .item')
        let imgTop1 = $('.index_pro .img-cont .item').eq(0).offset().top
        let imgTop2 = $('.index_pro .img-cont .item').eq(1).offset().top
        let imgTop3 = $('.index_pro .img-cont .item').eq(2).offset().top
        let imgTop4 = $('.index_pro .img-cont .item').eq(3).offset().top
        let imgTop5 = $('.index_pro .img-cont .item').eq(4).offset().top
        let imgTop6 = $('.index_pro .img-cont .item').eq(5).offset().top

        //产品部分右侧文字对应
        if (scroH >= imgTop1 - viewH / 2  && scroH < imgTop2 - viewH / 2) {
            $textElArr.eq(0).addClass('active').siblings().removeClass('active')
            $(".page-title").css("transform", "translateY(-0px)")
            $(".first").css("transform", "translateY(-0px)")
            $textElArr.css("transform", "translateY(-0px)")
            $(".title-line").css("width", $(".page-title h2").eq(0).width())

            let trans = viewH - (imgTop1 - scroH)
            $navElArr.eq(0).find('.pic-title').css('transform','translateY('+ -trans/2+'px)')
        }else if (scroH >= imgTop2 - viewH / 2 && scroH < imgTop3 - viewH / 2) {
            $(".title-line").css("width", $(".page-title h2").eq(1).width())
            $textElArr.eq(1).addClass('active').siblings().removeClass('active')
            $(".page-title").css("transform", "translateY(-46px)")
            $(".first").css("transform", "translateY(-50px)")

            let trans = viewH - (imgTop2 - scroH)
            $navElArr.eq(1).find('.pic-title').css('transform','translateY('+ -trans/2+'px)')
        } else if (scroH >= imgTop3 - viewH / 2 && scroH < imgTop4 - viewH / 2) {
            $(".title-line").css("width", $(".page-title h2").eq(2).width())
            $textElArr.eq(2).addClass('active').siblings().removeClass('active')
            $(".page-title").css("transform", "translateY(-92px)")
            $(".first").css("transform", "translateY(-100px)")

            let trans = viewH - (imgTop3 - scroH)
            $navElArr.eq(2).find('.pic-title').css('transform','translateY('+ -trans/2+'px)')
        } else if (scroH >= imgTop4 - viewH / 2 && scroH < imgTop5 - viewH / 2) {
            $(".title-line").css("width", $(".page-title h2").eq(3).width())
            $textElArr.eq(3).addClass('active').siblings().removeClass('active')
            $(".page-title").css("transform", "translateY(-138px)")
            $(".first").css("transform", "translateY(-150px)")

            let trans = viewH - (imgTop4 - scroH)
            $navElArr.eq(3).find('.pic-title').css('transform','translateY('+ -trans / 2+'px)')
        } else if (scroH >= imgTop5 - viewH / 2 && scroH < imgTop6 - viewH / 2) {
            $(".title-line").css("width", $(".page-title h2").eq(4).width())
            $textElArr.eq(4).addClass('active').siblings().removeClass('active')
            $(".page-title").css("transform", "translateY(-184px)")
            $(".first").css("transform", "translateY(-200px)")

            let trans = viewH - (imgTop5 - scroH)
            $navElArr.eq(4).find('.pic-title').css('transform','translateY('+ -trans / 2+'px)')
        } else if (scroH >= imgTop6 - viewH / 2) {
            $(".title-line").css("width", $(".page-title h2").eq(5).width())
            $textElArr.eq(5).addClass('active').siblings().removeClass('active')
            $(".page-title").css("transform", "translateY(-230px)")
            $(".first").css("transform", "translateY(-250px)")

            let trans = viewH - (imgTop6 - scroH)
            $navElArr.eq(5).find('.pic-title').css('transform','translateY('+ -trans / 2+'px)')
        } 

    }
});

// $(document).ready(function(){
//   var h2list = $('.index_pro .img-cont .item .pic-title h2')
//   var h3list = $('.index_pro .img-cont .item .pic-title h3')

//   h2list.each(function(i){
//     let stringArr = $(this).html().split("")
//     let html = ''
//     for(let i=0;i<stringArr.length;i++){
//        html += `<span style="transition-delay: ${i * 0.01 + 0.1}s">${stringArr[i]}</span>`
//     }
//     $(this).html(html)
//   })

//   h3list.each(function(i){
//     let stringArr = $(this).html().split("")
//     let html = ''
//     for(let i=0;i<stringArr.length;i++){
//        html += `<span style="transition-delay: ${i * 0.03 + 0.1}s">${stringArr[i]}</span>`
//     }
//     $(this).html(html)
//   })

// })